<template>
  <CModal
    class="new-image-modal"
    title="Add New Image"
    color="white"
    size="xl"
    centered
    :show.sync="$store.state.newImageAsideShow"
    :closeOnBackdrop="false"
  >
    <CForm>
      <div class="row">
        <div class="form-group col-sm-4">
          <label class="d-block">Image Type:</label>
          <v-select
            placeholder="Select.."
            v-model="$v.form.type.$model"
            :options="typeList"
            :searchable="false"
            @input="(t) => $emit('typeChanged', t)"
          >
            <template v-slot:option="type">
              <span>{{ type.label }}</span>
            </template>
          </v-select>
        </div>

        <div class="form-group col-sm-8">
          <label class="d-block">Tags:</label>
          <v-select
            placeholder="Select.."
            v-model="$v.form.tags.$model"
            :options="tagList"
            :reduce="tag => typeof tag === 'object' ? tag.name : tag"
            label="name"
            multiple
            taggable
          >
            <template v-slot:option="tag">
              <span>{{ tag.name ?? tag }}</span>
            </template>
          </v-select>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-sm-4">
          <label class="d-block">Image:</label>
          <input type="file" ref="inputFile" @change="onFileChanged">
          <CInput :value.sync="$v.form.image.$model" type="hidden" class="mb-0" />

          <!-- <CMedia
            class="py-1 preview d-none"
            :aside-image-props="{
              src: this.previewImage,
              shape: 'rounded',
              width: '100%',
              height: '90px'
            }"
          >
          </CMedia> -->
        </div>

        <CInput
          class="col-sm-8"
          label="File Name:"
          :value.sync="$v.form.name.$model"
          :isValid="checkIfValid('name')"
          :disabled="!form.image"
        />
      </div>

      <div class="row">
        <div class="col-sm">
			    <div class="size-info" v-if="size.width && size.height">
            <div>Width: {{ size.width }}px</div>
            <div>Height: {{ size.height }}px</div>
          </div>

          <cropper
            ref="cropper"
            class="cropper"
            :src="previewImage"
            :stencil-props="{
              handlers: {},
              movable: false,
              resizable: false,
              aspectRatio: aspectRatio,
            }"
            :stencil-size="stencilSize"
            :resize-image="{
              adjustStencil: false
            }"
            image-restriction="stencil"
            @change="cropperOnChange"
          />
        </div>
      </div>
    </CForm>

    <CElementCover :opacity="0.4" v-show="loading || submitted"/>

    <template #footer class="p-1">
      <!-- <CButton @click="$store.commit('toggle', 'newImageAsideShow')" color="secondary">Close</CButton> -->
      <CInputCheckbox v-if="form.type === 'Hero'" custom label="Add also as category image" :checked.sync="addAlsoAsCategoryImage" />
      <CButton @click="submit" color="primary" shape="pill" :disabled="submitted"><CIcon name="cil-send" /> Submit</CButton>
    </template>
  </CModal>

</template>

<script>
import { mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"

import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

export default {
  name: "NewImage",
  components: {
    Cropper,
  },
  props: {
    typeList: { default: () => [], type: Array },
    tagList: { default: () => [], type: Array },
    response: { default: () => ({}), type: Object },
  },
  data() {
    return {
      loading: false,
      submitted: false,
      errorAlertMessage: '',

      selectedImage: undefined,
      previewImage: undefined,

      form: { },

      canvas: null,
      size: {
				width: null,
				height: null,
			},
      addAlsoAsCategoryImage: false
    };
  },

  watch: {
    async reloadParams() {
      this.loading = true;
      if (this.$store.state.newImageAsideShow) {
        this.$refs.inputFile.value = null;
        this.selectedImage = this.previewImage = undefined;

        this.form = {
          type: this.type,
          tags: [],
          image: null,
          name: null
        };
        this.addAlsoAsCategoryImage = false;
      }
      this.loading = false;
    },
  },

  computed: {
    ...mapGetters(["errors"]),
    ...mapState(['filters']),

    reloadParams() { return [this.$store.state.newImageAsideShow]; },
    type() { return this.filters.images.type },
    aspectRatio() {
      return { 'Category': 50 / 9, 'Hero': 14 / 4 }[this.form.type]
    },
    stencilSize() {
      return { 'Category': { width: 1000, height: 180 }, 'Hero': { width: 980, height: 280 } }[this.form.type]
    },
  },

  mixins: [validationMixin],
  validations: {
    form: {
      type: { required },
      tags: { },
      image: {
        required: (fileName) => {
          if(!fileName)
            return false;

          var filename_arr = fileName.split(".");
          var file_ext = filename_arr[filename_arr.length - 1];
          return ["jpeg", "jpg", "png", "gif", "webp"].indexOf(file_ext) !== -1;
        }
      },
      name: { required },
    }
  },

  methods: {
    checkIfValid (fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === "");
    },

    onFileChanged(event) {
      const files = event.target.files;
      this.selectedImage = files[0];
      this.$v.form.image.$model = this.selectedImage.name;
      this.$v.form.name.$model = this.selectedImage.name.replace(/\..+$/, "");

      // Yüklenen dosya geçerli kriterleri sağlıyor mu? (validations)
      if (this.checkIfValid("image"))
        this.previewImage = URL.createObjectURL(this.selectedImage);
      else {
        this.selectedImage = undefined;
        this.previewImage = undefined;
        this.form.image = null;
      }
    },

    cropperOnChange({ coordinates, canvas }) {
      this.size.width = Math.round(coordinates.width);
			this.size.height = Math.round(coordinates.height);

      this.canvas = canvas;
    },

    formIsValid() {
      if (this.$v.form.$invalid) {
        this.$v.$touch();

        if (this.$v.form.type.$invalid) {
          this.$toast.error(`The image type is required!`);
        } else if (this.$v.form.image.$invalid) {
          this.$toast.error(`The image is required!`);
        }
      }
      return !this.$v.form.$invalid;
    },

    async submit() {
      if (!this.formIsValid()) {
        return;
      }

      try {
        this.submitted = true;
        const storeResponse = await this.handleImageFromCanvas();

        if (this.addAlsoAsCategoryImage) {
          this.form.type = 'Category';
          await new Promise(resolve => setTimeout(resolve, 500));
          await this.handleImageFromCanvas();
        }

        this.selectedImage = null;
        this.$emit('update:response', storeResponse);
        this.$store.commit('set', ['newImageAsideShow', false]);
        this.$toast.success(`${this.$options.filters.ucfirst(storeResponse.image.type)} image successfully added.`);
      } catch (error) {
        this.$toast.error(typeof this.errors === 'object'
          ? Object.values(this.errors).join()
          : this.errors
        );
      } finally {
        this.submitted = false;
      }
    },

    async handleImageFromCanvas() {
      return new Promise((resolve, reject) => {
        this.canvas.toBlob(async (blob) => {
          try {
            await this.uploadImage(blob);
            const storeResponse = await this.$axios.post(this.$backend.IMAGES.STORE, this.form);
            resolve(storeResponse?.data);
          } catch (error) {
            reject(error);
          }
        });
      });
    },

    async uploadImage(image) {
      const formData = new FormData();
      formData.append('image', image, this.selectedImage.name);
      formData.append('type', this.form.type);
      formData.append('name', this.form.name);

      await this.$axios.post(this.$backend.IMAGES.UPLOAD, formData)
        .then(({ data }) => {
          // Api'den gelen resim yolunu hidden inputa ata (response varsa valid olacak.)
          this.form.image = data.data.image;
        })
    },
  }
};
</script>

<style>
.new-image-modal .modal-header {
  padding: 0.75rem !important;
}
.new-image-modal .modal-footer {
  padding: 0.25rem !important;
}
.new-image-modal .form-errors div:nth-child(1):before {
  display: block;
  content: "Plase check!";
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.cropper {
	height: 320px;
	background: #e4e7ea;
}
.size-info {
  outline: 1px dotted #28666e;
  color: white;
  background-color: darkslategrey;
  font-size: 12px;
  padding: 0.375rem;
  position: absolute;
  right: 30px;
  bottom: 10px;
  opacity: 0.6;
  z-index: 9999;
}
</style>
