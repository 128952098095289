<template>
  <CCardBody>
    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="row">
            <!-- Type Filter -->
            <div class="col-lg-3 col-sm-5 col-filter mb-2 mb-lg-0">
              <v-select
                class="v-select-filter"
                placeholder="Type.."
                v-model="type"
                :options="typeList"
                :searchable="false"
              />
            </div>

            <!-- Tag Filter -->
            <div class="col-lg col-sm-7 col-filter mb-2 mb-lg-0">
              <v-select
                class="v-select-filter"
                placeholder="Tags.."
                v-model="tags"
                :options="tagList"
                :reduce="t => t.id"
                label="name"
                multiple
                :searchable="false"
                :loading="loading"
                @input="getAllItems"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <section class="articles">
      <Article v-for="(item, index) in loadedItems" :image="item" @assign="showAssignImage" @delete="(image) => deleteItem(image, index)"></Article>
    </section>

    <!-- Actions -->
    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>
        </div>
      </CCardBody>
    </CCard>

    <mc-spinner :opacity="0.8" v-show="loading" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />

    <AssignToCategory :image="selectedImage" />
    <AssignHeroImageToRestaurant :image="selectedImage" />
  </CCardBody>
</template>

<script>
import _debounce from 'lodash/debounce';
import { mapState } from 'vuex'
import Article from './Article.vue'
import AssignToCategory from "./AssignToCategory";
import AssignHeroImageToRestaurant from "./AssignHeroImageToRestaurant";

export default {
  name: "Images",
  components: {
    Article,
    AssignToCategory,
    AssignHeroImageToRestaurant
  },
  props: {
    images: { default: () => [], type: Array },
    typeList: { default: () => [], type: Array },
    tagList: { default: () => [], type: Array },
  },
  data() {
    return {
      total: 0,
      loading: false,
      selectedImage: null,

      // Element Cover Spinner
      mcSpinnerMarginTop: 0,
    };
  },

  computed: {
    ...mapState(['filters']),

    type: {
      get() { return this.filters.images.type },
      set(value) { this.filters.images.type = value },
    },
    tags: {
      get() { return this.filters.images.tags },
      set(value) { this.filters.images.tags = value },
    },
    loadedItems: {
      get() { return this.images; },
      set(newVal) { this.$emit('update:images', newVal); },
    },
  },

  watch: {
    type: { handler: 'typeFilter' },
  },

  mounted() {
    this.getAllItems();

    window.onscroll = () => {
      this.mcSpinnerMarginTop = document.documentElement.scrollTop;
    }
  },

  methods: {
    getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.IMAGES.GET_ALL),
        params = {
          type: this.type,
          tags: this.tags,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          this.total = data?.length ?? 0;
          this.loadedItems = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    // Filters
    typeFilter() {
      this.tags = [];
      this.$emit("typeChanged", this.type);

      this.getAllItems();
    },

    resetFilters() {
      if (this.$store.state.filterShow) {
        this.type = null;
        this.tags = [];
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },

    showAssignImage(image) {
      this.selectedImage = image;
      if (image.type == "category") {
        this.$store.commit('set', ['assignImageToCategoryAsideShow', true]);
      } else if (image.type == "hero") {
        this.$store.commit('set', ['assignHeroImageToRestaurantAsideShow', true]);
      }
    },

    async deleteItem(image, index) {
      try {
        this.loadedItems.splice(index, 1);
        await this.$axios.delete(this.$backend.IMAGES.DELETE.replace("{id}", image.id));
      } catch (error) {
        this.loadedItems.splice(index, 0, image);
        this.$toast.error(`${error.response.data.message}`);
      }
    },
  },
};
</script>


<style scoped>
.articles {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 24px;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 480px) {
  .articles {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}
</style>
