<template>
  <article>
    <div class="article-wrapper">
      <figure>
        <img :src="image.image_url" alt="" />
      </figure>
      <div class="article-body">
        <div class="d-flex flex-gap-1 mb-2">
          <CBadge v-for="(tag, index) in image.tags" color="info">{{ tag.name }}</CBadge>
        </div>
        <div class="d-flex justify-content-between">
          <CLink @click="showAssignImage">
            <span v-if="image.type == 'category'">Assign to Category</span>
            <span v-else-if="image.type == 'hero'">Assign to Restaurant</span>

            <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd"
                d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                clip-rule="evenodd" />
            </svg>
          </CLink>

          <div v-if="image.is_deletable">
            <div v-if="pendingDelete" class="d-flex align-items-center flex-gap-1">
              <span class="text-danger">Are you sure?</span>
              <CButton size="sm" color="link" @click="deleteItem">
                <CIcon name="cil-check-alt" />
              </CButton>
              <CButton size="sm" color="link" @click="pendingDelete = false">
                <CIcon name="cil-x" />
              </CButton>
            </div>

            <CButton v-else size="sm" color="danger" variant="ghost" shape="pill" @click="pendingDelete = true">
              <CIcon name="cil-trash" />
            </CButton>
          </div>

        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: "Article",
  props: {
    image: { default: () => ({}), type: Object },
  },
  data() {
    return {
      pendingDelete: false,
      timer: null
    };
  },
  watch: {
    pendingDelete(newVal) {
      if (newVal) {
        this.timer = setTimeout(() => {
          this.pendingDelete = false;
        }, 3000);
      } else {
        clearInterval(this.timer);
      }
    }
  },
  methods: {
    showAssignImage() {
      this.$emit('assign', this.image)
    },
    deleteItem() {
      this.pendingDelete = false
      this.$emit('delete', this.image)
    },
  },
};
</script>

<style scoped>
article {
  --img-scale: 1.00;
  --link-icon-translate: -3px;
  --link-icon-opacity: 0.5;

  position: relative;
  border-radius: 12px;
  box-shadow: none;
  transform-origin: center;
  transition: all 0.4s ease-in-out;
  overflow: hidden;
  outline: 1px solid #ebebeb;
}

/* article a::after {
  position: absolute;
  inset-block: 0;
  inset-inline: 0;
  cursor: pointer;
  content: "";
} */

.article-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

figure {
  margin: 0;
  padding: 0;
  aspect-ratio: 50 / 9;
  overflow: hidden;
}

article img {
  /* width: 100%; */
  max-width: 100%;
  transform-origin: center;
  transform: scale(var(--img-scale));
  transition: transform 0.4s ease-in-out;

  object-fit: cover;
  object-position: center;
}

.article-body {
  padding: 12px;
}

article a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #28666e;
}

article a:focus {
  outline: 1px dotted #28666e;
}

article a .icon {
  min-width: 24px;
  width: 24px;
  height: 24px;
  margin-left: 5px;
  transform: translateX(var(--link-icon-translate));
  opacity: var(--link-icon-opacity);
  transition: all 0.3s;
}

article:has(:hover, :focus) {
  --img-scale: 1.1;
  --link-icon-translate: 0;
  --link-icon-opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
</style>
