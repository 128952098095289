<template>
  <CSidebar
    aside
    :show="$store.state.assignImageToCategoryAsideShow"
    @update:show="(val) => $store.commit('set', ['assignImageToCategoryAsideShow', val])"
    colorScheme="light"
    overlaid
    size="lg"
  >
    <CSidebarClose @click.native="$store.commit('toggle', 'assignImageToCategoryAsideShow')" />

    <CListGroup class="scrollable list-group-accent mt-5">
      <CListGroupItem class="mb-0 list-group-item-accent-secondary bg-light text-center font-weight-bold text-uppercase">
        Assign to Category
      </CListGroupItem>

      <CListGroupItem class="list-group-item-divider list-group-item-accent-primary">

        <div class="image-container">
          <img :src="image?.image_url" />
        </div>

        <CForm @submit.prevent="add">
          <div class="form-group">
            <label class="d-block">Categories</label>
            <v-select
              class="v-select-style"
              placeholder="Select.."
              v-model="form.categories"
              :options="categories"
              :loading="loading && !categories.length"
              multiple
              @input="getRestaurantsByCategoryNames"
            />
          </div>

          <div class="form-group">
            <div class="d-flex justify-content-between">
              <label>Restaurants</label>
              <div class="d-flex flex-gap-2">
                <CLink @click="form.restaurants = restaurants.pluck('id')"><CIcon name="cil-library-add" /></CLink>
                <CLink @click="form.restaurants = []"><CIcon name="cil-library" /></CLink>
              </div>
            </div>
            <ul class="checklist">
              <li v-for="item in restaurants">
                <label class="for-checkbox">
                  <input
                    type="checkbox"
                    v-model="form.restaurants"
                    :value="item.id"
                  />
                  #{{ item.id }} - {{ item.restaurant_name }}
                </label>
              </li>
            </ul>
          </div>
        </CForm>
      </CListGroupItem>

      <CListGroupItem class="sticky-bottom list-group-item-accent-primary bg-white">
        <CButton
          color="primary"
          :disabled="!form.restaurants?.length"
          @click="assign"
        >
          <CIcon name="cil-send" /> Submit
        </CButton>
      </CListGroupItem>

    </CListGroup>

    <CElementCover :opacity="0.4" v-show="loading || submitted"/>
  </CSidebar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AssignToCategory",
  props: {
    image: { default: () => ({}), type: Object },
  },
  data() {
    return {
      form: { },
      categories: [],
      restaurants: [],

      loading: false,
      submitted: false,
      errorAlertMessage: '',
    };
  },

  watch: {
    async reloadParams() {
      this.loading = true;
      if (this.$store.state.assignImageToCategoryAsideShow) {
        this.form = {
          categories: [],
          restaurants: [],
        };
        this.restaurants = [];

        await Promise.all([
          !this.categories.length && this.getCategoryUniqueNames(),
        ].filter(Boolean));
      }
      this.loading = false;
    },
  },

  computed: {
    ...mapGetters(["errors"]),
    reloadParams() {
      return [this.$store.state.assignImageToCategoryAsideShow];
    },
  },


  methods: {
    async getCategoryUniqueNames() {
      await this.$axios
        .get(this.$backend.CATEGORIES.UNIQUE_NAMES)
        .then((response) => {
          this.categories = response.data;
        });
    },

    async getRestaurantsByCategoryNames() {
      if (!this.form.categories.length) {
        this.restaurants = [];
        return;
      }

      this.loading = true;

      await this.$axios
        .get(this.$backend.RESTAURANT.GET_ALL_BY_CATEGORY_NAMES, {
          params: { category_names: this.form.categories.toString() },
        })
        .then((response) => {
          this.restaurants = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async assign() {
      try {
        this.submitted = true;

        const {data} = await this.$axios.post(this.$backend.CATEGORIES.ASSIGN_BULK_IMAGE, {
          "image_id": this.image.id,
          "categories": this.form.categories.toString(),
          "restaurants": this.form.restaurants.toString(),
        });

        this.$store.commit('set', ['assignImageToCategoryAsideShow', false]);
        this.$toast.success(`${data.message}`);
      } catch (error) {
        this.$toast.error(typeof this.errors === 'object'
          ? Object.values(this.errors).join()
          : this.errors
        );
      } finally {
        this.submitted = false;
      }
    },
  }
};
</script>

<style scoped>
.scrollable {
  overflow-y: auto;
  height: 100%;
}

.image-container {
  padding: 0.25rem;
  outline: 3px solid #3498db;
  border-radius: 5px;
  margin-bottom: 20px;
}
.image-container img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
}

ul.checklist {
  list-style: none;
  padding-left: 0.5rem;
}
ul.checklist li {
  line-height: 1.2;
  font-size: 1em;
}
ul.checklist input {
  width: 1rem;
  height: 1rem;
}
label.for-checkbox, label.for-checkbox > input {
  cursor: pointer;
}
label.for-checkbox {
  display: flex;
  /* align-items: center; */
  gap: 0.25rem;
}
</style>
