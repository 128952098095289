<template>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-image" />
          <h5 class="d-inline ml-2">Images</h5>
          <div class="card-header-actions">
            <CButton color="primary" @click="addNewImage">
              <CIcon name="cil-plus" class="align-bottom" /><strong>Add New</strong> Image
            </CButton>
          </div>
        </CCardHeader>

        <Images :typeList="typeList" :tagList="tagList" :images.sync="images" @typeChanged="getTags" />
        <NewImage :typeList="typeList" :tagList="tagList" :response.sync="response" @typeChanged="getTags" />
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { mapState } from 'vuex'
import Images from "./Images";
import NewImage from "./NewImage";

export default {
  name: "ImagesMain",
  components: {
    Images,
    NewImage,
  },
  data() {
    return {
      images: [],
      tagList: [],
      typeList: ['Category', 'Hero'],
      response: {},
    };
  },
  watch: {
    'response.status': {
      handler: function (val) {
        if (val == "success") {
          // Image merging
          // this.images.push(this.response.image);

          // Tag merging
          this.response.image.tags.forEach(iTag => {
            if (!this.tagList.some(tag => tag.id === iTag.id)) {
              this.tagList.push(iTag);
            }
          });

          this.$store.state.filters.images.type = this.$options.filters.ucfirst(this.response.image.type);
          this.response.status = false;
        }
      },
    },
  },
  computed: {
    ...mapState(['filters']),

    type: {
      get() { return this.filters.images.type },
      set(value) { this.filters.images.type = value },
    },
  },

  async mounted () {
    await this.getTags(this.type = 'Category');
  },

  methods: {
    addNewImage() {
      this.$store.commit('set', ['newImageAsideShow', true]);
    },

    async getTags(type) {
      await this.$axios
        .get(this.$backend.IMAGES.TAGS, {
          params: { type: type },
        })
        .then((response) => {
          this.tagList = response.data;
        });
    },
  }
};
</script>
